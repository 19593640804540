<template>
  <f7-page v-if="isShow" :class="{ 'hide-qr': !hideQR, 'home-page splash-page': true }">
    <div class="main-container splash-content" :style="{ 'background-image': 'url(' + require('@/assets/images/wine-glass.png') + ')' }">
      <div class="container splash-bg">
        <div v-if="hideQR" class="content">
          <div class="phone-container">
            <img src="@/assets/images/new-splash.png" />
          </div>
        </div>
        <div class="content">
          <div class="content-right">
            <div v-if="hideQR">
              <img src="@/assets/images/logo/logo-name-color.svg" />
              <p class="signup-mobile">{{ $t.getTranslation('LBL_SPLASH_SUB_MESSAGE') }}</p>
              <f7-button class="login" fill large raised @click="continueBtn">
                {{ $t.getTranslation('LBL_SHOP') }}
              </f7-button>
            </div>
            <div v-else class="splash-qr">
              <img src="@/assets/images/qr-youzan.png" alt="Youzan QR Code" />
              <h3>{{ $t.getTranslation('LBL_SCAN_WITH_WECHAT') }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hideQR" class="parent-footer">
        <div class="footer">
          <div class="content-footer">
            <p class="menu-footer">
              <span v-for="footer in footerLinks" :key="'li_' + footer.link" n>
                <f7-link :href="footer.link" class="no-ripple">
                  {{ $t.getTranslation(footer.name) }}
                </f7-link>
              </span>
            </p>
            <p class="text-footer">
              <span>
                <f7-link :href="$configs.externalPageLinks.termsAndConditions" target="_blank" external class="no-ripple">{{ $t.getTranslation('LBL_TERMS_AND_CONDITIONS') }}</f7-link>
              </span>
              &
              <span
                ><a href="#" class=""
                  ><f7-link :href="$configs.externalPageLinks.privacyPolicy" target="_blank" external class="no-ripple">{{ $t.getTranslation('LBL_PRIVACY_POLICY') }}</f7-link></a
                ></span
              >
            </p>
            <div class="regulation">
              <p>增值电信业务经营许可证 : 合字B2-20210167</p>
              <p><f7-link :href="$configs.icp.link" target="_blank" external class="no-ripple">备案号（粤ICP备2021057820号-2）</f7-link></p>
            </div>
            <p class="translate">
              <span v-for="language in languageList" :key="'lg_' + language.Code" class="trans-btn"
                ><f7-link href="#" :class="{ active: language.Code === currentLanguageCode }" @click="changeLanguage(language.Code)">
                  <span v-if="language.Code == 'EN'">{{ language.Code }}</span>
                  <span v-else>{{ language.Name }}</span>
                </f7-link></span
              >
              &copy; {{ currentYear }} {{ $t.getTranslation('LBL_PJF_WINE') }}. {{ $t.getTranslation('LBL_ALL_RIGHTS_RESERVED') }}.
            </p>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, inject, computed, onMounted, onBeforeMount } from 'vue'
import { configs, extendedConfigs } from '@/utils/configs.js'
import { helpers } from '@/utils/helpers.js'
import { useStore } from '@/store'
import { get } from '@/utils/axios'

export default defineComponent({
  name: 'SplashPage',
  components: {},
  props: { f7router: Object },
  setup(props) {
    const store = useStore()
    const isShow = ref(true)
    const hideQR = ref(true)

    //store.dispatch('config/fetchData', { params: { languageList: 1 } })
    const languageList = computed(() => store.getters['config/getData']?.languageList)

    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const userToken = store.getters['user/getToken']
    const currentYear = new Date().getFullYear()
    const $t = inject('$translation')
    const profileInfo = ref(false)
    const footerLinks = ref([
      {
        link: '/about/',
        name: 'LBL_ABOUT_US'
      },
      {
        link: '/profile/userguide/',
        name: 'LBL_USER_GUIDE'
      },
      {
        link: '/contact/merchant/',
        name: 'LBL_MERCHANT'
      },
      {
        link: '/contact/',
        name: 'LBL_CONTACT_US'
      }
    ])

    let isWechat = helpers.isInsideWeixin()
    let isMobile = helpers.isMobile()

    onMounted(async () => {
      if (userToken != '') {
        profileInfo.value = await get('/user/info')
      }
    })

    const login = () => {
      props.f7router.navigate({ name: 'loginPage' })
    }

    const register = () => {
      props.f7router.navigate({ name: 'registerPage' })
    }

    const loginWechat = () => {
      let url = `${configs.baseURL}/webhook/wechat/login${isWechat ? '?wechat=1' : '?auth=1'}`
      url += '&lang=' + currentLanguageCode.value
      url += `&host=${window.location.host}`
      window.location.href = url
    }

    const continueBtn = () => {
      hideQR.value = false
      return
      isShow.value = false
      props.f7router.navigate({ name: 'shopPage' }, { reloadCurrent: true })
    }

    const changeLanguage = (languageCode) => {
      store.dispatch('translation/setLanguage', { languageCode })
      store.dispatch('translation/fetchData')
      store.dispatch('config/fetchData', { refetch: true })
    }

    return {
      profileInfo,
      continueBtn,
      userToken,
      login,
      register,
      loginWechat,
      currentLanguageCode,
      languageList,
      currentYear,
      changeLanguage,
      footerLinks,
      isWechat,
      isMobile,
      isShow,
      hideQR
    }
  }
})
</script>

<style>
@media only screen and (min-width: 900px) {
  .splash-render-page {
    height: 100% !important;
  }
}
.hide-qr.splash-page .content-right {
  padding: 0;
  width: 100%;
}
.hide-qr.splash-page .splash-content {
  align-items: center;
  justify-content: center;
  height: auto;
}
.splash-render-page .splash-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  /* margin: 100px 0px; */
}
.splash-render-page .splash-qr h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--f7-theme-color);
  margin: 0px 0px 0px;
}
/* .splash-render-page .splash-qr p {
  font-size: 14px !important;
  line-height: 1.8em;
  margin: 0px 0px 50px;
  width: 60ch;
} */
.splash-render-page .splash-qr img {
  width: 300px;
  height: auto;
  padding: 0;
  margin: 0 auto 60px;
  display: block;
}

.footer p {
  margin: 0px;
}

.login {
  margin-top: 10px;
}

.splash-render-page {
  height: 100% !important;
  width: 100% !important;
  position: relative !important;
  margin: 0px !important;
  top: unset !important;
  left: unset !important;
  border: none !important;
  border-radius: 0px !important;
}

.splash-render-page .signup-desktop {
  cursor: pointer;
}

.splash-render-page p.text {
  text-align: center;
}

.splash-render-page p.translate > span {
  padding: 5px 5px;
}

.splash-render-page p.translate > span > a.active {
  font-weight: bold;
}

.splash-render-page .content-right-two {
  width: 275px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 180px;
  line-height: 30px;
}

.splash-render-page .main-container {
  /* background-size: 180px; */
  background-position: 0px bottom;
  background-repeat: no-repeat;
}

.splash-render-page .splash-content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.splash-render-page .splash-bg {
  /*background: url('images/new-splash.png');*/
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-position: 50px 0px;
}

.splash-render-page .splash-bg .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.splash-render-page .login {
  background: #0c4d8f;
  padding: 10px 0px;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.splash-render-page .wechat {
  background: #2dc100;
  padding: 10px 0px;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 18px;
  width: 100%;
  text-align: center;
}

.splash-render-page .signup {
  text-decoration: none;
}

.splash-render-page img {
  height: 85px;
  padding-bottom: 30px;
}

.splash-render-page h2 {
  overflow: hidden;
  text-align: center;
  padding: 20px 0px;
  font-size: 14px;
  width: 100%;
  color: rgb(65, 65, 65);
}

.splash-render-page h2:before,
.splash-render-page h2:after {
  background-color: rgb(65, 65, 65);
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.splash-render-page h2:before {
  right: 0.5em;
  margin-left: -50%;
}

.splash-render-page h2:after {
  left: 0.5em;
  margin-right: -50%;
}

.splash-render-page .phone-container {
  width: 600px;
  height: 600px;
}

.splash-render-page .phone-container > img {
  width: 100%;
  height: 100%;
}

.splash-render-page .content-right {
  width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 180px;
}

.splash-render-page .content-right p {
  font-size: 12px;
  margin-top: 20px;
}
.splash-render-page .signup-mobile {
  display: none;
  padding-bottom: 20px;
}

.splash-render-page .footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  line-height: 30px;
  text-align: center;
}

.splash-render-page .parent-footer {
  position: fixed;
  bottom: 0px;
  padding-bottom: 40px;
}

.splash-render-page .content-footer {
  width: 750px;
}

/*.splash-render-page .menu-footer{
  word-spacing: 15px;
}*/

/*.splash-render-page .text-footer{
  margin-top: -10px;
}*/

.splash-render-page .menu-footer > span {
  padding: 0px 5px;
}

.translate > .active {
  font-weight: 700;
}
.trans-btn a.active ::before {
  display: none;
}

/* height */
@media screen and (max-height: 800px) {
  .splash-render-page .parent-footer {
    position: relative;
    bottom: 0px;
  }
}

/* phone */
@media screen and (min-width: 0px) and (max-width: 481px) {
  .wechat-already-logged-in {
    font-size: 14px !important;
  }

  .splash-render-page .splash-bg {
    background: none;
    display: block !important;
  }

  .splash-render-page .main-container {
    background-size: 160px;
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  .splash-render-page .splash-bg .content {
    flex: 1;
    padding: 0px 25px;
  }

  .splash-render-page .splash-bg {
    height: 400px;
  }

  .splash-render-page .phone-container {
    display: none;
  }

  .splash-render-page .content-right {
    padding-right: 0px !important;
    width: 300px;
  }

  .splash-render-page .signup-mobile {
    display: contents;
    padding-bottom: 20px;
  }
  .splash-render-page .signup-desktop {
    display: none;
  }

  .splash-render-page .login {
    margin-top: 20px;
  }

  .splash-render-page .content-right-two {
    padding-right: 0px;
    width: 280px;
    margin-top: 25px;
  }

  .splash-render-page .content-footer {
    width: 400px;
    font-size: 14px;
  }

  .login {
    margin-top: 0px;
  }
}

/* tablet */
@media screen and (min-width: 482px) and (max-width: 1380px) {
  .splash-bg {
    background: none;
    display: block !important;
  }

  .main-container {
    background-position-x: left;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  .splash-bg .content {
    flex: 1;
    margin-top: 60px;
  }

  .splash-bg {
    height: 650px;
  }

  .phone-container {
    display: none;
  }

  .content-right {
    padding-right: 0px !important;
    width: 400px !important;
  }

  .content-right-two {
    padding-right: 0px;
    width: 280px;
    margin-top: 25px;
  }

  .signup-mobile {
    display: contents;
    padding-bottom: 20px;
  }
  .signup-desktop {
    display: none;
  }

  .login {
    margin-top: 20px;
  }

  .menu-footer a {
    padding: 0 7px;
  }

  .content-footer {
    width: 350px;
    font-size: 14px;
  }
}
</style>
